<template>
<div>
    <div class="lead-search-area">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label for="type">Status</label>
                        <select class="form-control" id="type" v-model="filters_.status">
                            <option value="">All</option>
                            <option value="1">Active</option>
                            <option value="2">Rejected</option>
                            <option value="3">Canceled</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label for="type">By</label>
                        <select class="form-control" id="by" v-model="filters_.employee">
                            <option value="">All</option>
                            <option v-for="employee in employees" :key="employee.id" :value="employee.user_id">{{employee.user.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="lead-search">
                    <div class="form-group">
                        <label>Start</label>
                        <Datepicker v-model="filters_.start_date" :enableTimePicker="false" :autoApply="true" placeholder="Select Start Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="lead-search">
                    <div class="form-group">
                        <label>End</label>
                        <Datepicker v-model="filters_.end_date" :enableTimePicker="false" :autoApply="true" placeholder="Select End Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="lead-search">
                    <a class="refresh" v-on:click.prevent="filterData" href="#"><img class="img-fluid" src="@/assets/images/icons/refresh.svg" alt="">Refresh</a>
                </div>
            </div>
        </div>
    </div>

    <div class="leads-table-area addcustomer-table">
        <table id="raq_table" class="display">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Provider</th>
                    <th>Render Location</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Created Date</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
//import moment from "moment";

export default {
    name: "Transactions",
    components: {
        Datepicker
    },
    data() {
        return {
            filters_:{
                start_date:null,
                end_date:null,
                status:'',
                employee:'',
            },
            employees:[]
        };
    },
    created() {
        this.getEmployees()
    },
    methods: {
        getEmployees(){
            let thiss = this
            axios.get('get_employees_list',{params: { business_id: this.$storage.getStorageSync('business').active_business.id }}).then(function (response) {
                //console.log(response.data.data)
                thiss.employees = response.data.data
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message);
                }
            });
        },
        filterData() {
            $("#raq_table").dataTable().fnDraw();
        }
    },
    mounted() {
        let thiss = this;
        $("#raq_table")
        //.addClass("nowrap")
        .dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_raq",
                type: "POST",
                data: function (d) {
                    d.start_date = thiss.filters_.start_date
                    d.end_date = thiss.filters_.end_date
                    d.status = thiss.filters_.status
                    d.employee = thiss.filters_.employee
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                }
            },
            searching: false,
            // drawCallback: function () {
            //     //alert( 'Table redrawn '+new Date() );
            // },
            columnDefs: [
                {
                    targets: [2, 3,7],
                    orderable: false,
                },
            ],
            //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
        });
    },
};
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style>
.lead-search .date-controll{
    background-color:#fff !important;
    padding-left: 30px;
}
.dp__main{
    width: 100%;
}
.dp__input_icon{
    left: 13px;
}
.dp__clear_icon{
    right: -15px;
}
.counter-num h4{
    font-size: 25px;
}
</style>