<template>
    <AppContainer>
        <RAQ/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import RAQ from '@/components/dashboard/raq/Quotations.vue'
export default {
  name: 'quotations',
  components: {
    AppContainer,
    RAQ
  }
}
</script>